import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import { Grid, Typography } from "@mui/material";
import ProductionOverview from "./DataGrids/ProductionOverview/ProductionOverview";
import ProductionByCarrier from "./DataGrids/ProductionByCarrier/ProductionByCarrier";
import "react-table-6/react-table.css";
import { setContractStatuses } from "../../store/actions/contracts";
import ReadyToSellByCarrier from "./DataGrids/ReadyToSellByCarrier/ReadyToSellByCarrier";
import CarrierInformation from "./CarrierInformation";
import ProductionInformation from "./ProductionInformation";
import { Box } from "@mui/system";
import PieChartComponentAgentInfo from "./PieCharts/PieChartComponentAgentInfo";
import { useDispatch } from "react-redux";

const useStyles = makeStyles()(() => {
  return {
    typography: {
      color: "#003760",
    },
    productionYear: {
      paddingTop: 10,
      fontWeight: 500,
      fontSize: "2em",
    },
  };
});

const AgencyDetail = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [year, setYear] = useState(0);
  const agencyName = sessionStorage.getItem("agencyName");
  const agentTotal = sessionStorage.getItem("agent_total");

  const currentProduction = useSelector(
    ({ currentProduction }) => currentProduction
  );  
  
  const contracts = useSelector(({ contracts }) => contracts);

  useEffect(() => {
    if (contracts && Object.keys(contracts).length === 0) {
      dispatch(setContractStatuses(sessionStorage.getItem("agencyId")));
    }
  }, [contracts, dispatch]);

  return (
    <Grid container sx={{ backgroundColor: "#9e9e9e", p: 1, flexGrow: 1 }}>
      <Box
        sx={{ flexGrow: 1, backgroundColor: "#b1bec4", p: 1, borderRadius: 1 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              className={classes.typography}
              gutterBottom
              variant="h3"
              component="h2"
            >
              {agencyName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={`${classes.productionYear} ${classes.typography}`}
            >
              Summary For PY {year}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ProductionOverview year = {year} currentProduction={currentProduction} />
          </Grid>
          <Grid item xs={6}>
            <ProductionByCarrier
              year={year}
              isLoading={false}
            />
          </Grid>
          <Grid item xs={6}>
            <ProductionInformation
              agencyId={sessionStorage.getItem("agencyId")}
              classes={classes}
            />
          </Grid>

          <Grid item xs={12}>
            <CarrierInformation
              agencyId={sessionStorage.getItem("agencyId")}
              classes={classes}
              year={year}
              setYear={setYear}
            />
          </Grid>
          <Grid item xs={6}>
            {contracts.pieData !== undefined ? (
              <PieChartComponentAgentInfo
                agentTotal={agentTotal}
                contracts={contracts}
              />
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <ReadyToSellByCarrier />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default AgencyDetail;
