import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import classes from "../../DataGridTemplate/DataGridTemplate.module.css";
import { setFilteredTotals } from "../../../store/actions/production/filteredProductionTotals";
import ProductionStatisticsColumns from "./ProductionStatisticsColumns";
import ProductionStatisticsData from "./ProductionStatisticsData";

import {
  DataGridPro,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

const ProductionStatisticsMUI = (props) => {
  const { planYear, carrierName, productionData } = props;
  const data = productionData;
  const filterModel = useSelector(
    (state) => state.productionStatisticsForAgencyFull.filterModel
  );
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const columns = ProductionStatisticsColumns();

  const uniqueRows = data
    ? ProductionStatisticsData({
        data: data,
      }).sort((a, b) => a.agentName.localeCompare(b.agentName))
    : [];

  const sxToolBarContainer = { fontSize: 12, paddingBottom: 1 };
  const sxToolBarColumnsButton = {
    color: "black",
    backgroundColor: "whitesmoke",
  };

  /// Custom toolbar for the DataGrid
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={sxToolBarContainer}>
        <GridToolbarColumnsButton sx={sxToolBarColumnsButton} />
        <GridToolbarFilterButton sx={sxToolBarColumnsButton} />
        <GridToolbarDensitySelector sx={sxToolBarColumnsButton} />
        <GridToolbarExport sx={sxToolBarColumnsButton} />
      </GridToolbarContainer>
    );
  };

  const ODD_OPACITY = 0.2;

  /// StripedDataGrid is a styled version of DataGridPro
  /// The getRowClassName property is used to apply the even and odd CSS classes to the rows.
  const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "lightgrey",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  const sxBox = {
    height: 640,
    width: "auto",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(27, 133, 243, 0.8)",
    },
  };

  const MemoizedDataGrid = memo(StripedDataGrid);

  return (
    <div className={classes.myClass}>
      <Box sx={sxBox}>
        <MemoizedDataGrid
          apiRef={apiRef}
          slots={{ toolbar: CustomToolbar }}
          loading={false}
          rows={uniqueRows.sort((a, b) =>
            (a.agentname || "").localeCompare(b.agentname || "")
          )}
          columns={columns}
          pagination={{ rowsPerPageOptions: [25, 50, 100], pageSize: 25 }}
          disableRowSelectionOnClick
          {...((carrierName || planYear) && {
            initialState: {
              filter: {
                filterModel: filterModel,
              },
            },
          })}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          onStateChange={() => {
            const filteredSortedRowIds =
              gridFilteredSortedRowIdsSelector(apiRef);
            const filteredSortedRows = filteredSortedRowIds.map((id) =>
              apiRef.current.getRow(id)
            );
            const maTotal = filteredSortedRows.reduce(
              (sum, row) => sum + parseInt(row.ma),
              0
            );
            const msTotal = filteredSortedRows.reduce(
              (sum, row) => sum + parseInt(row.medsupp),
              0
            );
            const pdpTotal = filteredSortedRows.reduce(
              (sum, row) => sum + parseInt(row.pdp),
              0
            );

            dispatch(
              setFilteredTotals({
                maTotal: maTotal,
                msTotal: msTotal,
                pdpTotal: pdpTotal,
              })
            );
          }}
        />
      </Box>
    </div>
  );
};

export default ProductionStatisticsMUI;
