const ProductionStatisticsData = (props) => {
  const { data } = props;
  const uniqueRows = [];
  
  const dataRows = data?.map((row) => {    
    const effectiveDate = new Date(Number(row.effectivedate));
    const formattedDate = effectiveDate.toLocaleDateString();
    const id = row.agentName + row.carrierName + row.ma + row.medsupp + row.pdp + row.salesYear + row.county + row.state ;
    return {
      id: id,
      agentName: row.agentName,
      carrierName: row.carrierName,
      companyName: row.companyName,
      hlevel: row.hlevel,
      ma: row.ma,
      medsupp: row.medsupp,
      pdp: row.pdp,
      salesYear: row.salesYear,
      uplines: row.uplines,
      county: row.county,
      state: row.state,
      effectivedate: formattedDate,
    };    
  });

  dataRows.forEach((row) => {
    if (!uniqueRows.find((existingRow) => existingRow.id === row.id)) {
      uniqueRows.push(row);
    }
  });

  return uniqueRows;
};

export default ProductionStatisticsData;
