import React, { Fragment } from "react";
import { Typography, Paper } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ProductionByCarrierDataGrid from "./ProductionByCarrierDataGrid";
import { useSelector } from "react-redux";

const useStyles = makeStyles()(() => {
  return {
    root: {
      flexGrow: 1,
    },
    card: {
      background: "linear-gradient(to bottom right, white, #0e76bc) !important",
      minHeight: "fit-content",
    },
    typography: {
      color: "#003760",
    },
    table: {
      padding: 10,
    },
    input: {
      marginLeft: 8,
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  };
});

const ProductionByCarrier = (props) => {
  const { classes } = useStyles();
  const { year, isLoading } = props;
  let results = [];
  const data = useSelector(
    (state) => state.productionStatisticsForAgency.dataSummary
  );

  let ma_grand_total = 0;
  let medsupp_grand_total = 0;
  let pdp_grand_total = 0;

  const distinctCarrierIDs = Array.from(
    new Set(
      data
        .filter((item) => item.salesYear === year)
        .map((item) => item.carrierId)
    )
  );

  const distinctCarrierName = (carrierId) => {
    const results = Array.from(
      new Set(
        data
          .filter((item) => item.carrierId === carrierId)
          .map((item) => item.carrierName)
      )
    );
    return results[0];
  };

  const getAllCarrierTotals = () => {
    if (year !== 0) {
      distinctCarrierIDs.map((carrierId, index) => {
        const carrierResults = getCarrierTotals(carrierId);
        results.push(carrierResults);
      });
      results.push({
        id: 0,
        product: "Total",
        maTotal: ma_grand_total,
        msTotal: medsupp_grand_total,
        pdpTotal: pdp_grand_total,
      });
    }
  };

  const getCarrierTotals = (carrierId) => {
    const filterData = data.filter(
      (item) => item.carrierId === carrierId && item.salesYear === year
    );
    const carrierName = distinctCarrierName(carrierId);
    const ma_total = filterData.reduce(
      (sum, row) => sum + parseInt(row.agentCarrierMaGrandTotal),
      0
    );
    const medsupp_total = filterData.reduce(
      (sum, row) => sum + parseInt(row.agentCarrierMedsuppGrandTotal),
      0
    );
    const pdp_total = filterData.reduce(
      (sum, row) => sum + parseInt(row.agentCarrierPdpGrandTotal),
      0
    );

    ma_grand_total += ma_total;
    medsupp_grand_total += medsupp_total;
    pdp_grand_total += pdp_total;

    return {
      id: carrierId,
      product: carrierName,
      maTotal: ma_total,
      msTotal: medsupp_total,
      pdpTotal: pdp_total,
    };
  };

  getAllCarrierTotals();

  return (
    <Fragment>
      <Typography
        className={classes.typography}
        gutterBottom
        variant="h5"
        component="h3"
      >
        Production By Carrier
      </Typography>
      <Paper className={classes.paper}>
        <ProductionByCarrierDataGrid
          tableData={results}
          year={year}
          isLoading={isLoading}
        />
      </Paper>
    </Fragment>
  );
};

export default ProductionByCarrier;
